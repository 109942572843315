<template>
	<div class="order_list_table_class">
		<!--订单列表-->
		<div class="order_list_mage" v-if="!order_details_body">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="全部" name="0"></el-tab-pane>
				<el-tab-pane label="待支付" name="1"></el-tab-pane>
				<el-tab-pane label="已支付" name="2"></el-tab-pane>
				<el-tab-pane label="已退回" name="3"></el-tab-pane>
				<el-tab-pane label="已承保" name="4"></el-tab-pane>
				<el-tab-pane label="已取消" name="5"></el-tab-pane>
			</el-tabs>
			<div class="table_div_class">
				<el-table :data="table_list_data" border :row-class-name="tableRowClassName" :header-cell-style="getRowClass">
					<el-table-column prop="number" label="订单号" align="center"></el-table-column>
					<el-table-column prop="insure_record.insure_number" label="保单号" align="center"></el-table-column>
					<el-table-column prop="product.name" label="产品名称" align="center"></el-table-column>
					<el-table-column prop="policy_holder_name" label="单位名称" align="center"></el-table-column>
					<el-table-column prop="insured_peo" label="参保人数" align="center"></el-table-column>
					<el-table-column prop="order_state_mean" label="订单状态" align="center"></el-table-column>
					<el-table-column prop="money" label="应付金额" align="center"></el-table-column>
					<el-table-column prop="created_at" label="订单时间" align="center"></el-table-column>
					<el-table-column label="操作" align="center" width="180">
						<template slot-scope="scope">
							<el-button type="text" @click="order_details(scope.row)">查看详情</el-button>
							<span v-if="scope.row.insure_pay_confirm">
								<el-button type="text" style="margin-left: 10px" @click="upload_voucher(scope.row)" v-if="scope.row.insure_pay_confirm.status === 2 && scope.row. pay_state === 0">重新上传支付凭证</el-button>
								<el-button type="text" @click="reject_reason(scope.row)" v-if="scope.row.insure_pay_confirm.status === 2 && scope.row.pay_state === 0">驳回原因</el-button>
							</span>
							<span v-if="!scope.row.insure_pay_confirm && scope.row.pay_state === 0">
								<el-button type="text"  style="margin-left: 10px" @click="upload_voucher(scope.row)" v-if="scope.row.pay_state === 0">上传支付凭证</el-button>
								<el-button type="text" @click="payment_order(scope.row)" v-if="scope.row.pay_state === 0">立即支付</el-button>
								<el-button type="text" @click="cancel_order(scope.row.id)" v-if="scope.row.pay_state === 0">取消订单</el-button>
							</span>
							<el-button type="text" style="margin-left: 10px" @click="refund_order(scope.row)" v-if="scope.row.pay_state === 1">申请撤单</el-button>
							<el-button type="text" @click="again_insure(scope.row)" v-if="scope.row.pay_state === -1">重新投保</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="pagination_class">
					<el-pagination
							@current-change="handleCurrentChange"
							:current-page.sync="currentPage"
							:page-size="pagesize"
							layout="total, prev, pager, next"
							:total="total">
					</el-pagination>
				</div>
			</div>
		</div>
		<!--订单详情-->
		<div class="order_details_div" v-if="order_details_body">
			<el-page-header class="page_header" @back="order_details_body = false" content="订单详情"></el-page-header>
			<div class="order_top_status">
				<div class="order_num">
					<span class="title_num">订单编号：</span>
					<span class="number_order">{{detils_info.number}}</span>
				</div>
				<div class="order_status_div">
					<div class="div_width_tatus">
						<div class="left_width">
							<span class="title_font">订单状态：</span>
							<span class="status_font">{{detils_info.order_state_mean}}</span>
						</div>
						<div>
							<span class="title_font">订单金额：</span>
							<span class="many_order PingFangMedium">￥{{detils_info.money}}</span>
						</div>
					</div>
					<div class="sumber_button_div" v-if="operation_row.order_state !== 0">
						<el-button v-if="operation_row.pay_state === 0 || operation_row.insure_pay_confirm && operation_row.insure_pay_confirm.status === 2" @click="cancel_order(operation_row.id)">取消订单</el-button>
						<el-button type="primary" v-if="operation_row.pay_state === 0 || operation_row.insure_pay_confirm && operation_row.insure_pay_confirm.status === 2"  @click="payment_order(operation_row)">立即支付</el-button>
						<el-button type="primary" v-if="operation_row.pay_state === -1" @click="again_insure(operation_row)">重新投保</el-button>
					</div>
				</div>
			</div>
			<div class="order_desriptions">
				<el-descriptions title="订单信息">
					<el-descriptions-item label="保单状态" v-if="detils_info.insure_record">{{detils_info.insure_record.insure_state_mean}}</el-descriptions-item>
					<el-descriptions-item label="投保日期">{{detils_info.created_at}}</el-descriptions-item>
					<el-descriptions-item label="保障期限" v-if="detils_info.product">{{detils_info.product.insured_range}}</el-descriptions-item>
					<el-descriptions-item label="生效日期">{{detils_info.insured_start}}</el-descriptions-item>
					<el-descriptions-item label="单位名称">{{detils_info.policy_holder_name}}</el-descriptions-item>
					<el-descriptions-item label="产品名称" v-if="detils_info.product">{{detils_info.product.name}}</el-descriptions-item>
					<el-descriptions-item label="参保人数">{{detils_info.insured_peo}}</el-descriptions-item>
				</el-descriptions>
			</div>
			<div class="order_desriptions">
				<el-descriptions title="支付信息">
					<el-descriptions-item label="支付时间">{{detils_info.payed_at}}</el-descriptions-item>
					<el-descriptions-item label="支付方式">{{detils_info.pay_type_mean}}</el-descriptions-item>
					<el-descriptions-item label="支付流水号" v-if="detils_info.sunpay_order">{{detils_info.sunpay_order.trade_id}}</el-descriptions-item>
					<el-descriptions-item label="退款记录" v-if="detils_info.insure_order_refund && detils_info.insure_order_refund.length !== 0 ">
						<span class="look_refund_record" @click="refund_record_dialog = true">查看退款记录</span>
					</el-descriptions-item>
				</el-descriptions>
			</div>
			<div class="order_desriptions">
				<el-descriptions title="联系人信息">
					<el-descriptions-item label="联系人姓名" v-if="detils_info.contact_name">{{detils_info.contact_name}}</el-descriptions-item>
					<el-descriptions-item label="联系人电话" v-if="detils_info.policy_holder_mobile">{{detils_info.policy_holder_mobile}}</el-descriptions-item>
					<el-descriptions-item label="联系人邮箱" v-if="detils_info.contact_email">{{detils_info.contact_email}}</el-descriptions-item>
					<el-descriptions-item label="联系人地址" v-if="detils_info.contact_address">{{detils_info.contact_address}}</el-descriptions-item>
				</el-descriptions>
			</div>
			<div class="order_desriptions">
				<div class="insured_list_div">
					<div class="title_font">参保人列表</div>
					<div class="input_code">
						<el-input v-model="order_details_code" placeholder="请输入证件号"></el-input>
						<el-button type="primary" icon="el-icon-search" @click="order_details_search(1)">搜索</el-button>
					</div>
				</div>
				<div class="table_div_class">
					<el-table :data="order_details_table" border :row-class-name="tableRowClassName" :header-cell-style="getRowClass">
						<el-table-column prop="customer.name" label="被保险人姓名" align="center"></el-table-column>
						<el-table-column prop="customer.card_type_mean" label="证件类型" align="center"></el-table-column>
						<el-table-column prop="customer.card_number" label="证件号码" align="center"></el-table-column>
						<el-table-column prop="customer.sex_mean" label="性别" align="center"></el-table-column>
						<el-table-column prop="customer.birth" label="出生日期" align="center"></el-table-column>
						<el-table-column prop="mobile" label="手机号(选填)" align="center"></el-table-column>
						<el-table-column prop="order_state_mean" label="订单状态" align="center"></el-table-column>
					</el-table>
					<div class="pagination_class">
						<el-pagination
								@current-change="order_details_CurrentChange"
								:current-page.sync="order_details_currentPage"
								:page-size="order_details_pagesize"
								layout="total, prev, pager, next"
								:total="order_details_total">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		<!--上传凭证-->
		<el-dialog :visible.sync="upload_voucher_dialog" width="700px" append-to-body :show-close="false" :before-close="close_upload_voucher">
			<div class="refund_order_class">
				<div class="top_title_dialog">
					<div class="title_font PingFangMedium">上传支付凭证</div>
					<img class="close_icon" @click="close_upload_voucher" src="https://oss.nuohuipay.cn/ClientProject/mobile/guangfubao/images/1646363357475.png">
				</div>
				<div class="from_input_div">
					<div class="font_onut_div" style="margin-bottom: 20px">
						<div class="title_font PingFangMedium">应付金额(元)：</div>
						<div class="many_text_color">{{operation_row.money}}</div>
					</div>
					<div class="font_onut_div">
						<div class="title_font PingFangMedium">上传凭证：</div>
						<div class="uplod_width">
							<el-upload v-if="voucher_card_fileList.length<6"
							           class="upload-demo"
							           :action="url+ '/user/uploadOssFile'"
							           :limit="6" accept=".jpg, .jpeg, .png"
							           :show-file-list="false"
							           :on-success="(file,fileList)=>{return voucher_success(file,fileList)}">
								<div class="uplod_dev_upload">
									<i class="el-icon-circle-plus-outline icon_upload"></i>
									<div>上传照片</div>
								</div>
							</el-upload>
							<div class="uplod_list_img" v-for="(img,index) in voucher_card_fileList" :key="index">
								<img :src="img.url" @click="voucher_card_preview(img.url)">
								<span class="el-icon-error icon_error" @click="voucher_card_Remove(img.id)"></span>
							</div>
						</div>
					</div>
					<div class="refund_button_class">
						<el-button class="close_button" plain @click="close_upload_voucher">关闭</el-button>
						<el-button class="sumber_button_class" type="primary" @click="upload_voucher_sumber">提交</el-button>
					</div>
				</div>
			</div>
		</el-dialog>
		<!--退款明细-->
		<el-dialog :visible.sync="refund_record_dialog" width="600px" append-to-body :show-close="false">
			<div class="refund_order_class">
				<div class="top_title_dialog">
					<div class="title_font PingFangMedium">退款记录</div>
					<img class="close_icon" @click="refund_record_dialog = false" src="https://oss.nuohuipay.cn/ClientProject/mobile/guangfubao/images/1646363357475.png">
				</div>
				<div class="from_input_div">
					<div class="table_div_refund">
						<div class="top_title_table PingFangMedium">
							<div>退款流水号</div>
							<div>退款金额</div>
							<div>退款时间</div>
							<div>退款状态</div>
						</div>
						<div class="boday_hidele">
							<div class="table_list_clor PingFangMedium" v-for="(item,index) in detils_info.insure_order_refund" :key="index">
								<div>{{item.refund_code}}</div>
								<div>{{item.money}}</div>
								<div>{{item.created_at}}</div>
								<div>{{item.status_mean}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="close_button_bohui">
					<el-button class="button_class" type="primary" @click="refund_record_dialog = false">关闭</el-button>
				</div>
			</div>
		</el-dialog>
		<!--驳回原因-->
		<el-dialog :visible.sync="reject_reason_dialog" width="400px" append-to-body :show-close="false">
			<div class="refund_order_class">
				<div class="top_title_dialog">
					<div class="title_font PingFangMedium">驳回原因</div>
					<img class="close_icon" @click="reject_reason_dialog = false" src="https://oss.nuohuipay.cn/ClientProject/mobile/guangfubao/images/1646363357475.png">
				</div>
				<div class="from_input_div">
					<div class="font_onut_div" style="margin-bottom: 20px">
						<div class="title_font PingFangMedium">驳回原因：</div>
						<div class="font_conter" v-if="operation_row.insure_pay_confirm">{{operation_row.insure_pay_confirm.desc}}</div>
					</div>
					<div class="font_onut_div">
						<div class="title_font PingFangMedium">驳回时间：</div>
						<div class="font_conter" v-if="operation_row.insure_pay_confirm">{{operation_row.insure_pay_confirm.deal_time}}</div>
					</div>
				</div>
				<div class="close_button_bohui">
					<el-button class="button_class" type="primary" @click="reject_reason_dialog = false">关闭</el-button>
				</div>
			</div>
		</el-dialog>
		<!--申请撤单-->
		<el-dialog :visible.sync="refund_order_dialog" width="600px" append-to-body :show-close="false">
			<div class="refund_order_class">
				<div class="top_title_dialog">
					<div class="title_font PingFangMedium">申请撤单</div>
					<img class="close_icon" @click="close_refund" src="https://oss.nuohuipay.cn/ClientProject/mobile/guangfubao/images/1646363357475.png">
				</div>
				<div class="from_input_div">
					<el-form :model="refund_order_form" :rules="refund_order_rules" ref="refund_order_form" label-position="left" label-width="120px" class="demo-ruleForm">
						<el-form-item label="申请人" prop="applicant">
							<el-input v-model.trim="refund_order_form.applicant" placeholder="请输入申请人姓名" clearable style="width: 70%"></el-input>
						</el-form-item>
						<el-form-item label="撤单原因" prop="refund_reason">
							<el-select v-model="refund_order_form.refund_reason" placeholder="请选择撤单原因" clearable style="width: 70%">
								<el-option label="重复购买" value="1"></el-option>
								<el-option label="信息填写错误" value="2"></el-option>
								<el-option label="不想要了" value="3"></el-option>
								<el-option label="其他" value="4"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="撤单原因" prop="reason_remarks" v-if="refund_order_form.refund_reason === '4'">
							<el-input type="textarea" :rows="4" resize="none" v-model="refund_order_form.reason_remarks" placeholder="请输入撤单原因" style="width: 70%"></el-input>
						</el-form-item>
						<el-form-item label="备注" prop="remarks" v-else>
							<el-input type="textarea" :rows="4" resize="none" v-model="refund_order_form.remarks" placeholder="请输入备注" style="width: 70%"></el-input>
						</el-form-item>
						<el-form-item label="申请金额">
							<span class="color_font_class">￥{{operation_row.money}}</span>
						</el-form-item>
					</el-form>
					<div class="refund_button_class">
						<el-button class="close_button" plain @click="close_refund">关闭</el-button>
						<el-button class="sumber_button_class" type="primary" @click="refund_submitForm('refund_order_form')">提交</el-button>
					</div>
				</div>
			</div>
		</el-dialog>
		<el-image-viewer v-if="ImgsListDialog" :on-close="closeViewer" :url-list="[ImgsList]" :z-index="9999"></el-image-viewer>
	</div>
</template>

<script>
    import app from '../../../assets/api/apiurl'
    import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
    export default {
        name: "order_list_table",
        components: { ElImageViewer },
        data(){
            return{
                url:app.API_URL,
                activeName:'0',
                table_list_data:[],
                order_details_body:false,//订单详情显示
                currentPage:1,
                pagesize:20,
                total:null,
                order_details_currentPage:1,
                order_details_pagesize:20,
                order_details_total:null,
                operation_row:'',//操作当前条row
                detils_info:'',//查看详情数据

                refund_order_dialog:false,//申请撤单
                refund_order_form:{
                    applicant:'',//申请人
                    refund_reason:'',//撤单原因
                    reason_remarks:'',//撤单原因_备注
                    remarks:'',//备注
                },
                refund_order_rules:{
                    applicant: [
                        { required: true, message: '请输入申请人姓名', trigger: 'blur' },
                        { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' }
                    ],
                    refund_reason: [
                        { required: true, message: '请选择撤单原因', trigger: 'change' }
                    ],
                    reason_remarks: [
                        { required: true, message: '请输入撤单原因', trigger: 'change' }
                    ],
                },
                reject_reason_dialog:false,//驳回原因
                refund_record_dialog:false,//退款明细
                upload_voucher_dialog:false,//上传凭证
                voucher_card_fileList:[],
                ImgsListDialog:false,//预览
                ImgsList:'',//预览图

                order_details_code:'',//参保人证件号搜索
                order_details_table:[],//参保人列表
            }
        },
        watch:{
            $route(to,from){
                if(to.path === '/order_list'){
                    this.gettable_order_list(1);
                }
            }
        },
	    mounted(){
            this.gettable_order_list(1);
	    },
        methods: {
            handleClick(tab, event) {//点击顶部tabs
                this.gettable_order_list(1);
            },
	        gettable_order_list(val){//订单列表
                this.currentPage = val;
		        this.$ajax.get(app.API_URL+'/order/lists',{
		            params:{
                        type:this.activeName,
                        page:val,
                        limit:this.pagesize,
		            }
		        }).then(res=>{
		            if (res.data.code === 1){
		                this.table_list_data = res.data.data;
		                this.total = res.data.cnt;
		            }
		        })
	        },
            refund_order(row){//申请撤单
                this.operation_row = row;//当前数据row
                this.refund_order_dialog = true;
            },
            closeViewer() {// 关闭查看器
                this.ImgsListDialog = false
            },
            voucher_card_preview(url){//预览已上传凭证
                this.ImgsListDialog = true;
                this.ImgsList = url;
            },
            voucher_success(res, file){//上传凭证
                this.voucher_card_fileList.push({
	                id:res.data.file_id,
	                url:app.OSS_URL + res.data.path
                });
            },
            voucher_card_Remove(id){//删除已上传凭证
                for (let i=0;i<this.voucher_card_fileList.length;i++){
                    if (this.voucher_card_fileList[i].id === id){
                        this.voucher_card_fileList.splice(i,1)
                    }
                }
            },
            close_upload_voucher(){//关闭上传凭证
                this.upload_voucher_dialog = false;
                this.voucher_card_fileList = [];
            },
            upload_voucher_sumber(){//上传凭证提交
				let data = this.operation_row;//当前数据row
	            if (this.voucher_card_fileList.length === 0){
	                this.$message.error('请上传凭证');
	                return;
	            }
	            let file_ids = [];
                this.voucher_card_fileList.forEach(el=>{
                    file_ids.push(el.id)
                });
	            this.$ajax.post(app.API_URL+'/order/upload_pay_submit',{
                    id:data.id,
                    file_ids:file_ids.toString(),
	            }).then(res=>{
	                if (res.data.code === 1){
	                    this.$message.success('上传成功');
                        this.upload_voucher_dialog = false;
                        this.voucher_card_fileList = [];
                        this.gettable_order_list(this.currentPage);
	                }
	            })
            },
            payment_order(row){//支付跳转
                this.operation_row = row;//当前数据row
                this.$router.push({path:'/puer2/pay_select',query:{id:row.id}});
            },
            again_insure(row){//重新投保
                this.operation_row = row;//当前数据row
                this.$router.push({path:'/puer2/insure_page',query:{id:row.id,status:'again'}});
            },
            order_details(row){//订单详情
                this.operation_row = row;//当前数据row
	            this.order_details_body = true;
	            this.getorder_info();
	            this.order_details_search(1);
            },
	        getorder_info(){//获取详情数据
                this.$ajax.get(app.API_URL+'/order/info',{
                    params:{
                        id:this.operation_row.id,
	                    type:'detail'
                    }
                }).then(res=>{
                    if (res.data.code === 1){
                        this.detils_info = res.data.data;
                    }
                })
	        },
            upload_voucher(row){//上传凭证
                this.operation_row = row;//当前数据row
                this.upload_voucher_dialog = true;
            },
            reject_reason(row){//驳回原因
                this.operation_row = row;//当前数据row
                this.reject_reason_dialog = true;
            },
            close_refund(){//关闭撤单
                this.refund_order_dialog = false;
                this.$refs.refund_order_form.resetFields();
            },
            refund_submitForm(formName){//申请撤单提交
                let row = this.operation_row;
                let data = this.refund_order_form;
	            let des = '';
                if (data.refund_reason === '4'){
                    des = data.reason_remarks
                } else {
                    des = data.remarks
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                       this.$ajax.post(app.API_URL+'/order/apply',{
                           id:row.id,
                           name:data.applicant,
                           reason :data.refund_reason,
                           des:des,
                       }).then(res=>{
                           if (res.data.code === 1){
                               this.$message.success('申请成功');
                               this.close_refund();
                               this.gettable_order_list(this.currentPage);
                           }
                       })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            cancel_order(id){//取消订单
                this.$confirm('确定取消订单?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$ajax.post(app.API_URL+'/order/cancel',{
	                    id:id
                    }).then(res=>{
                        if (res.data.code === 1){
                            this.$message.success('取消成功');
                            this.gettable_order_list(this.currentPage);
                        }
                    })
                }).catch(() => {});
            },
            handleCurrentChange(val) {//订单分页
                this.currentPage = val;
                this.gettable_order_list(val);
            },
            order_details_search(val){//参保人搜索
                this.order_details_currentPage = val;
                this.$ajax.get(app.API_URL+'/order/query_insured',{
                   params:{
                       card_number:this.order_details_code,
                       order_id:this.operation_row.id,
                       page:val,
                       limit:this.order_details_pagesize,
                   }
                }).then(res=>{
                    if (res.data.code === 1){
                        this.order_details_table = res.data.data;
                        this.order_details_total = res.data.cnt
                    }
                })
            },
            order_details_CurrentChange(val) {//分页
                this.order_details_currentPage = val;
	            this.order_details_search(val);
            },
            tableRowClassName ({ row, rowIndex }) {//改变奇数行颜色
                if ((rowIndex + 1) % 2 === 0) {
                    return 'double';
                } else {
                    return '';
                }
            },
            getRowClass ({ rowIndex }) {//改变头部颜色
                if (rowIndex == 0) {
                    return 'background:#F6F6F8'
                } else {
                    return ''
                }
            },
        }
    }
</script>

<style lang="less" scoped>
	.order_list_table_class{
		padding: 14px 0;
		.order_list_mage{
			.table_div_class{
				padding: 10px;
				.pagination_class{
					display: flex;
					justify-content: flex-end;
					margin-top: 10px;
				}
			}
		}
		.order_details_div{
			padding: 0 20px;
			.table_div_class{
				padding: 10px 0;
				.pagination_class{
					display: flex;
					justify-content: flex-end;
					margin-top: 10px;
				}
			}
			.order_top_status{
				margin-top: 30px;
				border-bottom: 1px solid #E2E2E2;
				padding-bottom: 20px;
				.order_num{
					.title_num{
						color: #333;
						font-size: 15px;
						font-weight: bold;
					}
					.number_order{
						color: #999;
						font-size: 15px;
					}
				}
				.order_status_div{
					margin-top: 20px;
					display: flex;
					justify-content: space-between;
					align-items:center;
					.div_width_tatus{
						display: flex;
						justify-content: flex-start;
						.left_width{
							margin-right: 50px;
						}
						.status_font{
							color: #333333;
							font-size: 15px;
							font-weight: 500;
						}
						.many_order{
							color: #A72C1C;
							font-weight: bold;
							font-size: 15px;
						}
						.title_font{
							color: #999999;
							font-size: 15px;
							font-weight: 500;
						}
					}
					.sumber_button_div{
						button{
							width: 120px;
							margin-left: 25px;
						}
					}
				}
			}
			.order_desriptions{
				margin-top: 20px;
				.look_refund_record{
					color: #A72C1C;
					cursor: pointer;
				}
				.insured_list_div{
					display: flex;
					justify-content: space-between;
					align-items: center;
					.title_font{
						color: #303133;
						font-size: 16px;
						font-weight: 700;
					}
					.input_code{
						display: flex;
						justify-content: space-between;
						button{
							margin-left: 20px;
						}
					}
				}
			}
		}
	}
	.refund_order_class{
		.top_title_dialog{
			display: flex;
			justify-content: space-between;
			padding-top: 20px;
			.title_font{
				font-weight: bold;
				font-size: 18px;
				border-left: 4px solid #A72C1C;
				padding-left: 20px;
			}
			.close_icon{
				margin-right: 30px;
				width: 17px;
				height: 17px;
				cursor: pointer;
			}
		}
		.from_input_div{
			padding: 20px 25px;
			.color_font_class{
				color: #A72C1C;
				font-weight: bold;
				font-size: 16px;
			}
			.refund_button_class{
				margin-top: 50px;
				text-align: center;
				.close_button{
					width: 128px;
				}
				.sumber_button_class{
					width: 128px;
				}
			}
			.font_onut_div{
				display: flex;
				justify-content: flex-start;
				.uplod_width{
					width: 80%;
					display: flex;
					flex-wrap:wrap;
					justify-content: flex-start;
					.uplod_dev_upload{
						width: 100px;
						height: 70px;
						border: 1px dashed #D8D8D8;
						color: #D8D8D8;
						font-size: 14px;
						border-radius: 4px;
						padding-top: 30px;
						margin-right: 10px;
						margin-bottom: 10px;
						.icon_upload{
							font-size: 20px;
						}
					}
					.uplod_list_img{
						width: 100px;
						height: 100px;
						border: 1px solid #D8D8D8;
						cursor: pointer;
						position: relative;
						margin-right: 10px;
						margin-bottom: 10px;
						img{
							width: 100%;
							height: 100%;
							display: block;
						}
						.icon_error{
							font-size: 20px;
							color: #A72C1C;
							position: absolute;
							right: -6px;
							top: -6px;
							cursor: pointer;
						}
					}
				}
				.title_font{
					font-size: 14px;
					font-weight: bold;
					width: 100px;
				}
				.font_conter{
					font-size: 14px;
					color: #999999;
					width: 90%;
				}
				.many_text_color{
					color: #A72C1C;
					font-size: 14px;
				}
			}
		}
		.close_button_bohui{
			text-align: center;
			padding-bottom: 30px;
			.button_class{
				width: 130px;
			}
		}
		.table_div_refund{
			border: 1px solid #A72C1C;
			border-radius: 11px;
			overflow: hidden;
			.top_title_table{
				display: flex;
				justify-content: space-between;
				background: #F9EDEB;
				color: #333;
				font-size: 13px;
				font-weight: bold;
				border-bottom: 1px solid #A72C1C;
				div{
					padding: 10px 0;
					width: 50%;
					text-align: center;
					border-right: 1px solid #A72C1C;
					&:last-child{
						border-right: 0;
					}
				}
			}
			.boday_hidele{
				.table_list_clor{
					display: flex;
					justify-content: space-between;
					color: #333;
					font-size: 13px;
					font-weight: 500;
					background: #fff;
					border-bottom: 1px solid #A72C1C;
					&:last-child{
						border-bottom: none;
					}
					div{
						padding: 15px 0;
						width: 50%;
						text-align: center;
						border-right: 1px solid #A72C1C;
						&:last-child{
							border-right: 0;
						}
					}
				}
			}
		}
	}
</style>
<style>
	.double {
		background: #F8F8F8 !important;
	}
	.page_header .el-page-header__content{
		font-size: 14px !important;
		font-weight: bold;
	}
	.order_list_table_class .el-tabs__nav-wrap{
		padding-left: 20px;
	}
</style>